@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
@layer base {
  html {
    font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply bg-[#f5f5f7] antialiased;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

/* Variables de Color */
:root {
  --color-background: #f5f5f7;
  --color-white: #ffffff;
  --color-black: #1d1d1f;
  --color-link: #0066cc;
  --color-link-dark: #2997ff;
  --color-dark-bg: #161617;
  --color-gray: #86868b;
  --color-nav-bg: rgba(0, 0, 0, 0.8);
  --color-mist: rgba(255, 255, 255, 0.72);
  --color-black-80: rgba(0, 0, 0, 0.8);
  --color-black-48: rgba(0, 0, 0, 0.48);
  --color-white-80: rgba(255, 255, 255, 0.8);
  --color-athens-gray: #f5f5f7;
  --color-mischka: #d2d2d7;
  --color-dark-secondary: #424245;
  --color-dark-tertiary: #68686a;
  --color-light-secondary: #e8e8ed;
  --color-light-tertiary: #f5f5f7;
}

/* Componentes principales */
@layer components {
  /* Tipografía */
  .text-title {
    @apply text-[40px] font-bold leading-[44px] tracking-normal text-[#1d1d1f];
  }

  .text-title-elevated {
    @apply text-[48px] font-bold leading-[52px] tracking-[-0.144px];
  }

  .text-subtitle {
    @apply text-xl leading-[26px] tracking-[0.34px] text-[#1d1d1f];
  }

  .text-caption {
    @apply text-[17px] leading-[21px] tracking-[-0.37px];
  }

  .text-eyebrow {
    @apply text-[12px] leading-[16px] tracking-[0.23px] font-semibold uppercase;
  }

  /* Enlaces */
  .link-primary {
    @apply text-[#0066cc] hover:underline text-[17px] tracking-[-0.37px] leading-[21px] transition-colors duration-300;
  }

  .link-dark {
    @apply text-[#2997ff] hover:underline transition-colors duration-300;
  }

  .link-secondary {
    @apply text-[#86868b] hover:text-[#1d1d1f] transition-colors duration-300;
  }

  /* Grid System */
  .grid-layout {
    @apply grid gap-3 mx-auto max-w-[1488px] px-3;
    grid-template-columns: 1fr;
  }

  .grid-layout-tight {
    @apply grid gap-[12px];
  }

  @screen md {
    .grid-layout {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  /* Contenedores */
  .container-large {
    @apply w-full max-w-[1488px] mx-auto px-3;
  }

  .container-medium {
    @apply w-full max-w-[1200px] mx-auto px-3;
  }

  .container-small {
    @apply w-full max-w-[738px] mx-auto px-3;
  }

  /* Cards y Secciones */
  .card-wrapper {
    @apply w-full max-w-[738px] mx-auto bg-white rounded-2xl shadow-sm;
  }

  .card-content {
    @apply bg-[#fbfbfd] min-h-[624px] h-full rounded-2xl;
  }

  .card-inner {
    @apply p-12 text-center relative top-11;
  }

  .card-dark {
    @apply bg-[#161617] text-white;
  }

  /* Navegación */
  .nav-bar {
    @apply fixed w-full z-50 bg-black/95 h-[44px] backdrop-blur;
  }

  .nav-link {
    @apply text-[12px] leading-[44px] text-white/80 hover:text-white transition-colors duration-300;
  }

  /* Botones */
  .button-primary {
    @apply text-[#0066cc] text-[17px] hover:underline inline-flex items-center transition-all duration-300 hover:translate-x-1;
  }

  .button-dark {
    @apply text-[#2997ff] text-[17px] hover:underline inline-flex items-center transition-all duration-300 hover:translate-x-1;
  }

  .button-secondary {
    @apply bg-[#e8e8ed] text-[#1d1d1f] px-4 py-2 rounded-full hover:bg-[#d2d2d7] transition-colors duration-300;
  }
}

/* Utilidades */
@layer utilities {
  .backdrop-blur {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  .backdrop-saturate {
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
  }

  .backdrop-contrast {
    backdrop-filter: contrast(1.1) blur(20px);
    -webkit-backdrop-filter: contrast(1.1) blur(20px);
  }

  .scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .text-balance {
    text-wrap: balance;
  }

  .text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

/* Fondos Temáticos */
.bg-light-elevated {
  @apply bg-white/[.72] backdrop-blur;
}

.bg-dark-elevated {
  @apply bg-[#1d1d1f]/[.72] backdrop-blur;
}

/* Contenido Interior */
.content-inner {
  @apply bg-[#fbfbfd] p-8 h-full;
}

.content-inner-dark {
  @apply bg-[#161617] p-8 h-full text-white;
}

/* Bordes */
.border-light {
  @apply border border-[#d2d2d7];
}

.border-dark {
  @apply border border-[#424245];
}

.border-separated {
  @apply border-b border-[#d2d2d7] last:border-b-0;
}

/* Espaciado y Superposición */
.overlap-section {
  @apply relative -mt-11 w-full;
}

.overlap-left {
  @apply md:pr-6;
}

.overlap-right {
  @apply md:pl-6;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

/* Bordes de sección */
.section-border {
  @apply w-full h-3 relative;
}

.section-border-top {
  @apply mx-auto max-w-[1488px];
}

.section-border-bottom {
  @apply w-full;
}

/* Contenedores de imagen */
.image-container {
  @apply relative w-full h-1/2 overflow-hidden;
}

.image-content {
  @apply w-full h-full object-cover transition-transform duration-700;
}

.image-hover {
  @apply hover:scale-105 transition-transform duration-700;
}

/* Sombras */
.shadow-elevated {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.shadow-subtle {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Animaciones */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

.fade-on-scroll {
  @apply opacity-0 transition-opacity duration-500;
}

.fade-on-scroll.visible {
  @apply opacity-100;
}

.hover-lift {
  @apply transition-transform duration-300 hover:-translate-y-1;
}

.hover-scale {
  @apply transition-transform duration-300 hover:scale-[1.02];
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .text-title {
    @apply text-[32px] leading-[36px];
  }

  .text-title-elevated {
    @apply text-[36px] leading-[40px];
  }

  .text-subtitle {
    @apply text-lg leading-6;
  }

  .card-inner {
    @apply p-8;
  }

  .container-large,
  .container-medium,
  .container-small {
    @apply px-4;
  }

  .content-inner,
  .content-inner-dark {
    @apply p-6;
  }

  .grid-layout {
    @apply gap-4;
  }

  .grid-layout-tight {
    @apply gap-3;
  }
}

@media (max-width: 640px) {
  .text-title {
    @apply text-[28px] leading-[32px];
  }

  .text-title-elevated {
    @apply text-[32px] leading-[36px];
  }

  .text-subtitle {
    @apply text-base leading-[22px];
  }

  .card-inner {
    @apply p-6;
  }

  .grid-layout,
  .grid-layout-tight {
    @apply gap-2;
  }
}